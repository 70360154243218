<template>
   <navi-bar>
     <img src="@/assets_mobile/img/icon_customer.png"/>
     <span>{{$t('front.common.qna')}}<em>Qna</em></span>
   </navi-bar>
  <section class="sub-box">
    <div class="msgreadwrap" v-if="model">
      <div class="msgreadhead">
        <h2 class="msgreadname">
          <em class="accounticon">{{$t('front.qnaTypeCategory.' + model.faqType)}}</em>
          <em v-if="model.status === 'WAIT'" class="waiticon">{{$t('front.qnaStatus.wait')}}</em>
          <em v-if="model.status === 'ANSWER'" class="compleicon">{{$t('front.qnaStatus.answer')}}</em>
          {{model.title}}
        </h2>
        <p class="msgreadtime">
          <span class="date">{{model.memName}}</span>
          <span class="time">{{dateFormatForMobile(model.regDate)}}</span>
        </p>
      </div>
      <div class="msgreadconwrap">
        <h2 class="msgreadconname">{{model.content}}</h2>
        <div class="msgreadcon" v-if="replyContent">
          {{replyContent}}
        </div>
      </div>
    </div>

    <div class="btnwrap">
      <a class="canceal" v-if="prevId" @click="onClickPrevNext('prev')">{{$t('front.board.prev')}}</a>
      <a class="ok" @click="goPageByName('qnaList')">{{$t('front.board.list')}}</a>
      <a class="canceal" v-if="nextId" @click="onClickPrevNext('next')">{{$t('front.board.next')}}</a>
    </div>

  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import QnaRead from '@/views/member/cscenter/qna/read'

export default {
  name: 'QnaReadMobile',
  components: {
    NaviBar
  },
  mixins: [QnaRead]
}
</script>

<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
